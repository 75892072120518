<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <template>
          <step-indicator
            :current="current"
            :total="this.$route.meta.action === 'store' ? 3 : 2"
            currentColor="rgb(255, 121, 26)"
            defaultColor="rgb(130, 140, 153)"
            :handleClick="handleClick"
          ></step-indicator>
        </template>

        <b-card-body>
          <div v-if="current == 0">
            <b-form>
              <b-form-group>
                <label for="menunjuk-surat">Menunjuk Surat</label>
                <b-form-file
                  v-model="letter"
                  :state="Boolean(letter)"
                  :placeholder="placeholderFile"
                  drop-placeholder="Taruh file disini..."
                  accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                  @change="handleFileUpload($event)"
                ></b-form-file>
                <b-button ref="resetLetter" type="reset" hidden></b-button>
              </b-form-group>
            </b-form>

            <b-form-group>
              <label for="nomor-surat">Nomor Surat</label>
              <b-form-input
                v-model="model.letter_number"
                placeholder="Masukkan Nomor Surat"
                :formatter="letterNumberFormatter"
              ></b-form-input>
              <p class="fs-6 mb-0 text-danger" v-if="letterNumberErrorMessage">
                {{ letterNumberErrorMessage }}
              </p>
            </b-form-group>

            <b-form-group>
              <label for="tanggal-surat">Tanggal Surat</label>
              <b-form-datepicker
                locale="id"
                v-model="model.letter_date"
                :max="new Date()"
                id="example-datepicker"
                placeholder="Pilih Tanggal Surat"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Perihal</label>
              <b-form-textarea
                id="textarea"
                placeholder="Masukkan perihal..."
                rows="3"
                max-rows="6"
                v-model="model.enclosure"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div v-if="current == 1">
            <b-form-group>
              <label for="kantor-tujuan">Kantor/Kota Tujuan</label>
              <b-form-input
                v-model="model.destination_office"
                placeholder="Masukkan Kantor/Kota Tujuan..."
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Tanggal Berangkat</label>
              <b-form-datepicker
                locale="id"
                :min="new Date()"
                :max="model.return_date"
                v-model="model.departure_date"
                id="departure_date"
                placeholder="Pilih Tanggal Berangkat"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Tanggal Kembali</label>
              <b-form-datepicker
                locale="id"
                :min="model.departure_date || new Date()"
                v-model="model.return_date"
                id="return_date"
                placeholder="Pilih Tanggal Kembali"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Lama Perjalanan Dinas</label>
              <b-form-input v-model="model.total_days" readonly></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Jenis Perjalanan Dinas</label>
              <b-form-select
                v-model="model.sppd_type_id"
                :options="options_pd"
              ></b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat"
                >Jenis Fasilitas Perjalanan Dinas</label
              >
              <b-form-select
                v-model="model.sppd_facility_type_id"
                :options="options_fpd"
              ></b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Alat Transportasi</label>
              <b-form-select
                v-model="model.transportation"
                :options="options_trans"
              ></b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="menunjuk-surat">Maksud Perjalanan Dinas</label>
              <b-form-textarea
                id="textarea"
                placeholder="Masukkan Maksud Perjalanan Dinas..."
                rows="3"
                max-rows="6"
                v-model="model.purpose"
                :formatter="purposeFormatter"
              ></b-form-textarea>
              <p class="fs-6 mb-0 text-danger" v-if="purposeErrorMessage">
                {{ purposeErrorMessage }}
              </p>
            </b-form-group>

            <!-- <b-form-group>
              <label for="menunjuk-surat">Catatan Lainnya</label>
              <b-form-textarea
                id="textarea"
                placeholder="Masukkan Catatan Lainnya..."
                rows="3"
                max-rows="6"
                v-model="model.note"
                :formatter="noteFormatter"
              ></b-form-textarea>
              <p class="fs-6 mb-0 text-danger" v-if="noteErrorMessage">
                {{ noteErrorMessage }}
              </p>
            </b-form-group> -->
          </div>

          <div v-if="current == 2">
            <b-tabs v-model="employeeTypeIndex">
              <b-tab active title="Pegawai">
                <label class="mr-sm-4" for="inline-form-custom-select-pref">
                  Cari Pegawai
                </label>
                <multiselect
                  v-model="pegawai"
                  label="nama"
                  track-by="nippos"
                  :options="pegawaiLists"
                  :custom-label="customLabelPegawai"
                ></multiselect>
                <span v-if="existsMessage" class="existsMessage text-danger">
                  {{ existsMessage }}
                </span>

                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="checkIsExists"
                  :disabled="!pegawai"
                >
                  Tambah
                </b-button>

                <b-table
                  ref="tablePegawai"
                  striped
                  hover
                  responsive
                  show-empty
                  class="position-relative pt-2"
                  primary-key="nippos"
                  :items="pegawais"
                  :fields="fieldPegawais"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(permission_letter)="data">
                    {{
                      data.item.permission_letter
                        ? `${data.item.permission_letter.substr(0, 10)}...`
                        : data.item.needUpload
                        ? 'Belum Upload'
                        : '-'
                    }}
                  </template>

                  <template #cell(action)="data">
                    <div style="min-width: 200px">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        size="sm"
                        class="btn-icon float-sm-left mr-1"
                        @click="showModalDetail(data.item, data.index)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        size="sm"
                        class="btn-icon mr-1"
                        @click="showModalEdit(data.item, data.index)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        size="sm"
                        class="btn-icon"
                        @click="confirmDelete(data.index)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-tab>

              <b-tab v-if="userLoggedIn.role_id !== 99" title="Non Pegawai">
                <label class="mr-sm-4" for="inline-form-custom-select-pref">
                  Cari Non Pegawai
                </label>
                <multiselect
                  v-model="pegawai"
                  label="name"
                  track-by="nik"
                  :options="nonPegawaiLists"
                  :custom-label="customLabelNonPegawai"
                ></multiselect>
                <span v-if="existsMessage" class="existsMessage text-danger">
                  {{ existsMessage }}
                </span>

                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="checkIsExists"
                  :disabled="!pegawai"
                >
                  Tambah
                </b-button>

                <b-table
                  ref="tableNonPegawai"
                  striped
                  hover
                  responsive
                  show-empty
                  class="position-relative pt-2"
                  primary-key="nik"
                  :items="nonPegawais"
                  :fields="fieldNonPegawais"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(action)="data">
                    <div style="min-width: 200px">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        size="sm"
                        class="btn-icon float-sm-left mr-1"
                        @click="showModalDetail(data.item, data.index)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        size="sm"
                        class="btn-icon mr-1"
                        @click="showModalEdit(data.item, data.index)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        size="sm"
                        class="btn-icon"
                        @click="confirmDelete(data.index)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>

            <b-modal
              v-model="isShowModalPegawai"
              size="lg"
              :hide-header-close="modalPegawaiAction !== 'show'"
              :hide-footer="modalPegawaiAction === 'show'"
              :title="titleModalPegawai"
              ok-title="Simpan"
              :ok-disabled="okModalPegawaiDisabled"
              cancel-title="Batal"
              @ok="savePegawai"
              @cancel="toggleShowModalPegawai"
              modal-class="px-0"
            >
              <div v-if="pegawai">
                <b-row class="mt-1">
                  <b-col cols="12" md="4" v-if="pegawai.nippos || pegawai.nik">
                    <h6>{{ pegawai.nippos ? 'NIPPOS' : 'NIK' }}</h6>
                    <p class="card-text mb-25">
                      {{ pegawai.nippos || pegawai.nik || '-' }}
                    </p>
                  </b-col>
                  <b-col cols="12" md="4">
                    <h6>Nama</h6>
                    <p class="card-text mb-25">
                      {{ pegawai.nama || pegawai.name || '-' }}
                    </p>
                  </b-col>
                  <b-col cols="12" md="4">
                    <h6>NPWP</h6>
                    <p class="card-text mb-25">{{ pegawai.npwp || '-' }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" class="mt-2">
                    <h6>
                      {{
                        employeeTypeIndex === 0
                          ? 'Jabatan'
                          : 'Jenis Non Pegawai'
                      }}
                    </h6>
                    <p class="card-text mb-25">
                      {{
                        employeeTypeIndex === 0
                          ? pegawai.descjabatan
                          : pegawai.type_non_employee.name
                      }}
                    </p>
                  </b-col>
                  <b-col
                    v-if="employeeTypeIndex === 0"
                    cols="12"
                    md="6"
                    class="mt-2"
                  >
                    <h6>Bagian/Unit Kerja</h6>
                    <p class="card-text mb-25">
                      {{ pegawai.namabagian || '-' }}
                    </p>
                  </b-col>
                  <b-col cols="12" md="6" class="mt-2">
                    <h6>Kantor Asal</h6>
                    <p class="card-text mb-25">
                      {{ pegawai.namaktr || pegawai.office || '-' }}
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <h6>Atasan / Yang Mengajukan</h6>
                    <p
                      v-if="modalPegawaiAction === 'show'"
                      class="card-text mb-25"
                    >
                      {{
                        selectedSubmitterType === 'pegawai'
                          ? `${selectedSubmitter.nippos} / ${selectedSubmitter.nama} / ${selectedSubmitter.descjabatan}`
                          : `${selectedSubmitter.nik} / ${selectedSubmitter.name} / ${selectedSubmitter.type_non_employee.name}`
                      }}
                    </p>
                    <b-form-group v-else>
                      <b-form-radio-group
                        v-model="selectedSubmitterType"
                        :options="selectedSubmitterTypeOptions"
                      >
                      </b-form-radio-group>
                      <multiselect
                        v-model="selectedSubmitter"
                        :label="
                          selectedSubmitterType === 'pegawai' ? 'nama' : 'name'
                        "
                        :track-by="
                          selectedSubmitterType === 'pegawai' ? 'nippos' : 'nik'
                        "
                        :options="
                          selectedSubmitterType === 'pegawai'
                            ? approvalLists
                            : nonPegawaiLists
                        "
                        :custom-label="
                          selectedSubmitterType === 'pegawai'
                            ? customLabelPegawai
                            : customLabelNonPegawai
                        "
                        class="mt-1"
                        placeholder="Pilih atasan / yang mengajukan"
                      ></multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <h6>Yang Memberi Perintah</h6>
                    <p
                      v-if="modalPegawaiAction === 'show'"
                      class="card-text mb-25"
                    >
                      {{
                        selectedInstructorType === 'pegawai'
                          ? `${selectedInstructor.nippos} / ${selectedInstructor.nama} / ${selectedInstructor.descjabatan}`
                          : `${selectedInstructor.nik} / ${selectedInstructor.name} / ${selectedInstructor.type_non_employee.name}`
                      }}
                    </p>
                    <b-form-group v-else>
                      <b-form-radio-group
                        v-model="selectedInstructorType"
                        :options="selectedInstructorTypeOptions"
                      >
                      </b-form-radio-group>
                      <multiselect
                        v-model="selectedInstructor"
                        :label="
                          selectedInstructorType === 'pegawai' ? 'nama' : 'name'
                        "
                        :track-by="
                          selectedInstructorType === 'pegawai'
                            ? 'nippos'
                            : 'nik'
                        "
                        :options="
                          selectedInstructorType === 'pegawai'
                            ? approvalLists
                            : nonPegawaiLists
                        "
                        :custom-label="
                          selectedInstructorType === 'pegawai'
                            ? customLabelPegawai
                            : customLabelNonPegawai
                        "
                        class="mt-1"
                        placeholder="Pilih yang memberi perintah"
                      ></multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12" v-if="pegawai.needUpload">
                    <b-form-group>
                      <h6 v-if="modalPegawaiAction === 'show'">Surat Izin</h6>
                      <label v-else class="font-italic">
                        Pegawai sudah melebihi batas maksimal perjalanan dinas.
                        Silahkan upload surat izin.
                      </label>
                      <p
                        v-if="modalPegawaiAction === 'show'"
                        class="card-text mb-25"
                      >
                        {{ suratIzinFilename || 'Belum Upload' }}
                      </p>
                      <b-form-file
                        v-else
                        v-model="suratIzin"
                        :state="
                          modalPegawaiAction === 'add'
                            ? Boolean(suratIzin)
                            : Boolean(placeholderSuratIzin)
                        "
                        :placeholder="placeholderSuratIzin"
                        drop-placeholder="Taruh file disini..."
                        accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                        @change="handleUploadSuratIzin($event)"
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-modal>
          </div>
        </b-card-body>

        <b-card-footer
          class="d-flex flex-column flex-sm-row justify-content-sm-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="ml-0 mr-0"
            @click="handleCancel"
          >
            Batal
          </b-button>

          <b-overlay
            v-if="
              (this.$route.meta.action === 'store' && current == 2) ||
              this.$route.meta.action !== 'store'
            "
            :show="loadingSaveAsDraft"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block mt-1 mt-sm-0 ml-sm-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              :disabled="loadingSaveAsDraft || loadingSubmit"
              class="w-100"
              @click="draft"
            >
              Simpan sebagai draft
            </b-button>
          </b-overlay>

          <b-button
            v-if="
              this.$route.meta.action === 'store' ? current < 2 : current < 1
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mt-sm-0 ml-0 ml-sm-1"
            @click="next"
            :disabled="current === 1 && !stepTwoFulfilled"
          >
            Selanjutnya
          </b-button>

          <b-overlay
            :show="loadingSubmit"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block ml-sm-1 mt-1 mt-sm-0"
          >
            <b-button
              v-if="
                this.$route.meta.action === 'store'
                  ? current === 2
                  : current === 1
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="w-100"
              :disabled="
                loadingSubmit ||
                loadingSaveAsDraft ||
                (this.$route.meta.action === 'edit' && !stepTwoFulfilled)
              "
              @click="ajukan"
            >
              {{ this.$route.meta.action === 'store' ? 'Ajukan' : 'Simpan' }}
            </b-button>
          </b-overlay>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
.existsMessage {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}
</style>

<script>
/* eslint-disable func-names, object-shorthand */
import {
  BCard,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BFormSelect,
  BFormRadioGroup,
  BTable,
  BTabs,
  BTab,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import 'vue2-datepicker/index.css'
import StepIndicator from 'vue-step-indicator'
import Multiselect from 'vue-multiselect'
import moment from 'moment'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,
    BFormRadioGroup,
    BTable,
    BTabs,
    BTab,
    BModal,
    BOverlay,
    StepIndicator,
    Multiselect,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'SppdForm',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/sppd',
        redirect: 'manajemen-sppd',
      },
      letter: null,
      suratIzin: null,
      suratIzinFilename: '',
      placeholderFile: 'Pilih file disini...',
      placeholderSuratIzin: 'Pilih file disini...',
      model: {
        letter: null,
        letter_number: null,
        letter_date: null,
        enclosure: null,

        destination_office: null,
        departure_date: null,
        return_date: null,
        total_days: null,
        sppd_type_id: null,
        sppd_facility_type_id: null,
        transportation: null,
        purpose: null,
        // note: null,

        is_draft: null,

        employees: [],
        submiters: [],
        instructors: [],
      },
      current: 0,
      confirmPass: null,
      options_pd: [
        { value: null, text: 'Pilih Perjalanan Dinas' },
        { value: 1, text: 'Jabatan' },
        { value: 2, text: 'Pemeriksaan' },
        // { value: 3, text: 'Pindah' },
      ],
      options_fpd: [
        { value: null, text: 'Pilih Fasilitas Perjalanan Dinas' },
        { value: 1, text: 'Perjalanan Dinas Biasa' },
        { value: 2, text: 'Perjalanan Dinas Fasilitas Khusus' },
      ],
      options_trans: [
        { value: null, text: 'Pilih Transportasi' },
        { value: 1, text: 'Dinas' },
        { value: 2, text: 'Pribadi' },
        { value: 3, text: 'Umum' },
      ],
      jenis_nonpegawai: [
        {
          label: 'Direksi',
          value: 'Direksi',
        },
        {
          label: 'Tenaga Kontrak',
          value: 'Tenaga Kontrak',
        },
        {
          label: 'Mitra Kerja',
          value: 'Mitra Kerja',
        },
        {
          label: 'Tamu Perusahaan',
          value: 'Tamu Perusahaan',
        },
        {
          label: 'Pengurus Pensiunan POS',
          value: 'Pengurus Pensiunan POS',
        },
        {
          label: 'Pendamping Komisaris / Direksi',
          value: 'Pendamping Komisaris / Direksi',
        },
      ],
      messages: '',
      formats: 'DD/MM/YYYY',
      lang: {
        days: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days',
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range',
        },
      },
      fieldPegawais: [
        'no',
        { key: 'nippos', label: 'NIPPOS' },
        { key: 'nama', label: 'Nama' },
        { key: 'descjabatan', label: 'Jabatan' },
        { key: 'namabagian', label: 'Bagian/Unit Kerja' },
        { key: 'namaktr', label: 'Kantor Asal' },
        { key: 'permission_letter', label: 'Surat Izin' },
        { key: 'action', label: 'Aksi', thStyle: 'width: 200px' },
      ],
      fieldNonPegawais: [
        'no',
        { key: 'nik', label: 'NIK' },
        { key: 'name', label: 'Nama' },
        {
          key: 'type_non_employee.name',
          label: 'Jenis Non Pegawai',
        },
        { key: 'office', label: 'Kantor Asal' },
        { key: 'action', label: 'Aksi', thStyle: 'width: 200px' },
      ],
      items: [],
      pegawaiLists: [],
      nonPegawaiLists: [],
      approvalLists: [],
      employeeTypeIndex: 0,
      pegawai: null,
      pegawais: [],
      nonPegawais: [],
      pegawaiSubmitters: [],
      nonPegawaiSubmitters: [],
      pegawaiInstructors: [],
      nonPegawaiInstructors: [],
      isShowModalPegawai: false,
      modalPegawaiAction: 'add',
      userLoggedIn: getUserData(),
      selectedSubmitter: null,
      selectedSubmitterType: 'pegawai',
      selectedSubmitterTypeOptions: [
        { text: 'Pegawai', value: 'pegawai' },
        // { text: 'Non Pegawai', value: 'non-pegawai' },
      ],
      selectedInstructor: null,
      selectedInstructorType: 'pegawai',
      selectedInstructorTypeOptions: [
        { text: 'Pegawai', value: 'pegawai' },
        // { text: 'Non Pegawai', value: 'non-pegawai' },
      ],
      selectedEmployee: null,
      existsMessage: '',
      loadingUpload: false,
      letterNumberErrorMessage: '',
      purposeErrorMessage: '',
      noteErrorMessage: '',
      loadingSubmit: false,
      loadingSaveAsDraft: false,
    }
  },
  computed: {
    okModalPegawaiDisabled() {
      return (
        !this.selectedSubmitter ||
        !this.selectedInstructor ||
        this.loadingUpload
      )
    },
    titleModalPegawai() {
      let title = 'Tambah Pegawai'

      if (this.modalPegawaiAction === 'show') {
        title = 'Detail Pegawai'
      } else if (this.modalPegawaiAction === 'edit') {
        title = 'Edit Pegawai'
      }

      if (this.employeeTypeIndex === 1) {
        title = title.replace('Pegawai', 'Non Pegawai')
      }

      return title
    },
    stepTwoFulfilled() {
      return (
        this.model.destination_office &&
        this.model.departure_date &&
        this.model.return_date &&
        this.model.sppd_type_id &&
        this.model.sppd_facility_type_id &&
        this.model.transportation &&
        this.model.purpose
      )
    },
  },
  watch: {
    employeeTypeIndex: function () {
      this.existsMessage = ''
      this.pegawai = null
    },
    'model.departure_date': function () {
      this.calculateDateBetween()
    },
    'model.return_date': function () {
      this.calculateDateBetween()
    },
    isShowModalPegawai: function (newValue) {
      if (!newValue) {
        // on modal closed
        this.existsMessage = ''
        this.pegawai = null
        this.selectedEmployee = null
        this.modalPegawaiAction = 'add'
        this.selectedSubmitter = null
        this.selectedSubmitterType = 'pegawai'
        this.selectedInstructor = null
        this.selectedInstructorType = 'pegawai'
        this.placeholderSuratIzin = 'Pilih file disini...'
        this.suratIzinFilename = null
        this.suratIzin = null
      }
    },
    selectedSubmitterType: function (newValue) {
      const submitters =
        newValue === 'pegawai' ? this.pegawaiLists : this.nonPegawaiLists
      const key = newValue === 'pegawai' ? 'nippos' : 'nik'
      if (
        !submitters.find(
          submitter => submitter[key] == this.selectedSubmitter?.[key],
        )
      ) {
        this.selectedSubmitter = null
      }
    },
    selectedInstructorType: function (newValue) {
      const instructors =
        newValue === 'pegawai' ? this.pegawaiLists : this.nonPegawaiLists
      const key = newValue === 'pegawai' ? 'nippos' : 'nik'
      if (
        !instructors.find(
          instructor => instructor[key] == this.selectedInstructor?.[key],
        )
      ) {
        this.selectedInstructor = null
      }
    },
  },
  mounted() {
    this.getData()
    this.getPegawai()
    this.getNonPegawai()
    this.getApprovals()
  },
  methods: {
    getData() {
      if (this.$route.meta.action == 'edit') {
        axios
          .get(`${this.config.api}/detail_info/${this.$route.params.id}`)
          .then(response => {
            this.model = response.data.data
          })
      }
    },
    ajukan() {
      this.loadingSubmit = true
      this.model.is_draft = null
      this.save()
    },
    draft() {
      this.loadingSaveAsDraft = true
      this.model.is_draft = true
      this.save()
    },
    serializeData(item) {
      if (item.type !== 'pegawai') {
        const { nik, name, type, type_non_employee } = item
        return {
          nik,
          name,
          type,
          position: type_non_employee.name,
          phone: '',
          email: '',
        }
      }
      return item
    },
    save() {
      if (
        !this.pegawais.length &&
        !this.nonPegawais.length &&
        this.$route.meta.action === 'store'
      ) {
        this.$bvToast.toast('Gagal, mohon tambahkan minimal 1 data pegawai', {
          title: 'Tambah SPPD',
          variant: 'danger',
          solid: true,
        })
        this.loadingSubmit = false
        this.loadingSaveAsDraft = false
      } else {
        const _ = this
        if (this.$route.meta.action == 'store') {
          const employeeNonPegawais = _.nonPegawais.map(
            ({
              nik,
              name,
              type,
              type_non_employee,
              level,
              no_rek,
              bagian,
              nopend,
              office,
              regional,
            }) => ({
              nik,
              name,
              type,
              no_rek,
              position: type_non_employee.name,
              level,
              phone: '',
              email: '',
              bagian,
              nopend,
              office,
              regional,
            }),
          )
          const pegawaiSubmitters = _.pegawaiSubmitters.map(this.serializeData)
          const nonPegawaiSubmitters = _.nonPegawaiSubmitters.map(
            this.serializeData,
          )
          const pegawaiInstructors = _.pegawaiInstructors.map(
            this.serializeData,
          )
          const nonPegawaiInstructors = _.nonPegawaiInstructors.map(
            this.serializeData,
          )
          const pegawaiPermissionLetters = _.pegawais.map(
            ({ permission_letter }) => permission_letter || '',
          )
          const nonPegawaiPermissionLetters = _.nonPegawais.map(
            ({ permission_letter }) => permission_letter || '',
          )
          _.model.employees = [..._.pegawais, ...employeeNonPegawais]
          _.model.submiters = [...pegawaiSubmitters, ...nonPegawaiSubmitters]
          _.model.instructors = [
            ...pegawaiInstructors,
            ...nonPegawaiInstructors,
          ]
          _.model.permission_letters = [
            ...pegawaiPermissionLetters,
            ...nonPegawaiPermissionLetters,
          ]

          axios
            .post(_.config.api, _.model)
            .then(() => {
              _.$router.push({
                name: _.config.redirect,
                params: {
                  event: 'success',
                  title: 'Ajukan SPPD',
                  text: 'SPPD berhasil diajukan',
                },
              })
              _.loadingSubmit = false
              _.loadingSaveAsDraft = false
            })
            .catch(e => {
              let vm = this
              vm.showDismissibleAlert = true
              const errorData = e.response.data
              if (errorData.errors?.employees?.length > 0) {
                vm.messages = errorData.errors?.employees[0]
              } else if (typeof errorData?.message === 'object') {
                vm.messages = errorData?.message?.errors[0]?.msg
              } else {
                vm.messages = errorData?.message
              }
              this.$bvToast.toast(vm.messages, {
                title: 'Tambah SPPD',
                variant: 'danger',
                solid: true,
              })
              vm.loadingSubmit = false
              vm.loadingSaveAsDraft = false
            })
        } else {
          const {
            id,
            letter,
            letter_number,
            letter_date,
            enclosure,
            destination_office,
            departure_date,
            return_date,
            total_days,
            sppd_type_id,
            sppd_facility_type_id,
            transportation,
            purpose,
            // note,
            is_draft,
          } = _.model
          const data = {
            id,
            letter,
            letter_number,
            letter_date,
            enclosure,
            destination_office,
            departure_date,
            return_date,
            total_days,
            sppd_type_id,
            sppd_facility_type_id,
            transportation,
            purpose,
            // note,
            is_draft,
          }
          axios
            .patch(_.config.api, data)
            .then(res => {
              _.$router.push({
                name: _.config.redirect,
                params: {
                  event: 'success',
                  title: 'Update SPPD Berhasil',
                  text: res.data.message,
                },
              })
              _.loadingSubmit = false
              _.loadingSaveAsDraft = false
            })
            .catch(e => {
              let vm = this
              vm.showDismissibleAlert = true
              if (typeof e.response.data.message === 'object') {
                vm.messages = e.response.data.message.errors[0].msg
              } else {
                vm.messages = e.response.data.message
              }
              vm.loadingSubmit = false
              vm.loadingSaveAsDraft = false
            })
        }
      }
    },
    handleCancel() {
      this.$router.go(-1)
    },
    handleClick(index) {
      if (!this.stepTwoFulfilled && index === 2) {
        return
      }
      this.current = index
    },
    next() {
      this.current += 1
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          this.$refs.resetLetter.click()
          this.letter = null
          this.model.letter = null
          this.placeholderFile = 'Pilih file disini...'
          this.$bvToast.toast(
            'Ukuran File Melebihi 2 MB, Silahkan Upload Kembali dengan ukuran file yang sesuai',
            {
              title: 'Tambah SPPD',
              variant: 'danger',
              solid: true,
            },
          )

          return
        }

        this.placeholderFile = file.name
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', 'surat sppd')

        axios
          .post('/misc/upload-file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(resp => {
            this.model.letter = resp.data.filename
          })
          .catch(err => {
            this.makeToast('danger', 'Terjadi kesalahan', err.message)
          })
      }
    },
    handleUploadSuratIzin(event) {
      this.loadingUpload = true
      const file = event.target.files[0]
      this.placeholderSuratIzin = event.target.files[0].name
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'surat izin')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(resp => {
          this.loadingUpload = false
          this.suratIzinFilename = resp.data.filename
        })
        .catch(err => {
          this.loadingUpload = false
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    getPegawai() {
      axios
        .get(
          `/misc/employees/v3?region=${this.userLoggedIn.regional}&noppen=${this.userLoggedIn.nopend}`,
        )
        .then(resp => {
          const filteredPegawai = resp.data.data.filter(
            ({ nippos }) => nippos === this.userLoggedIn.nippos,
          )

          this.pegawai = filteredPegawai.length ? filteredPegawai[0] : null

          if (this.userLoggedIn.role_id !== 99) {
            this.pegawaiLists = resp.data.data
          } else {
            this.pegawaiLists = filteredPegawai
          }
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    getNonPegawai() {
      axios
        .get('/misc/non-employees')
        .then(resp => {
          this.nonPegawaiLists = resp.data.data
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    getApprovals() {
      const { regional, nopend } = this.userLoggedIn
      let url = `/misc/employees/v3?region=${regional}&status=true`

      if (regional != '99') {
        url += `&noppen=${nopend}`
      }

      axios
        .get(url)
        .then(resp => {
          this.approvalLists = resp.data.data
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    toggleShowModalPegawai() {
      this.isShowModalPegawai = !this.isShowModalPegawai
    },
    savePegawai() {
      const {
        selectedSubmitter,
        selectedSubmitterType,
        selectedInstructor,
        selectedInstructorType,
        pegawai,
        suratIzinFilename,
      } = this

      const dataPegawai = {
        ...pegawai,
        permission_letter: suratIzinFilename,
        type: this.employeeTypeIndex === 0 ? 'pegawai' : 'non-pegawai',
      }
      const dataSubmitter = {
        ...selectedSubmitter,
        type: selectedSubmitterType,
      }
      const dataInstructor = {
        ...selectedInstructor,
        type: selectedInstructorType,
      }

      if (this.employeeTypeIndex === 0) {
        if (this.modalPegawaiAction === 'add') {
          // employeeTypeIndex
          this.pegawais.push(dataPegawai)
          this.pegawaiSubmitters.push(dataSubmitter)
          this.pegawaiInstructors.push(dataInstructor)
        } else {
          this.pegawais[this.selectedEmployee] = dataPegawai
          this.pegawaiSubmitters[this.selectedEmployee] = dataSubmitter
          this.pegawaiInstructors[this.selectedEmployee] = dataInstructor
        }
        this.$refs.tablePegawai.refresh()
      } else {
        if (this.modalPegawaiAction === 'add') {
          this.nonPegawais.push(dataPegawai)
          this.nonPegawaiSubmitters.push(dataSubmitter)
          this.nonPegawaiInstructors.push(dataInstructor)
        } else {
          this.nonPegawais[this.selectedEmployee] = dataPegawai
          this.nonPegawaiSubmitters[this.selectedEmployee] = dataSubmitter
          this.nonPegawaiInstructors[this.selectedEmployee] = dataInstructor
        }
        this.$refs.tableNonPegawai.refresh()
      }

      this.pegawai = null
    },
    calculateDateBetween() {
      let _ = this
      if (_.model.departure_date <= _.model.return_date) {
        const startDate = new Date(_.model.departure_date)
        const returnDate = new Date(_.model.return_date)

        const differenceInTime = returnDate.getTime() - startDate.getTime()
        const differenceInDays = differenceInTime / (1000 * 3600 * 24)

        _.model.total_days = differenceInDays + 1
      }
    },
    showModalDetail(pegawai, index) {
      const submitter =
        this.employeeTypeIndex === 0
          ? this.pegawaiSubmitters[index]
          : this.nonPegawaiSubmitters[index]
      const instructor =
        this.employeeTypeIndex === 0
          ? this.pegawaiInstructors[index]
          : this.nonPegawaiInstructors[index]

      this.modalPegawaiAction = 'show'
      this.pegawai = pegawai
      this.suratIzinFilename = pegawai.permission_letter
      this.selectedSubmitter = submitter
      this.selectedSubmitterType = submitter.type
      this.selectedInstructor = instructor
      this.selectedInstructorType = instructor.type
      this.toggleShowModalPegawai()
    },
    showModalEdit(pegawai, index) {
      const submitter =
        this.employeeTypeIndex === 0
          ? this.pegawaiSubmitters[index]
          : this.nonPegawaiSubmitters[index]
      const instructor =
        this.employeeTypeIndex === 0
          ? this.pegawaiInstructors[index]
          : this.nonPegawaiInstructors[index]

      this.modalPegawaiAction = 'edit'
      this.pegawai = pegawai
      this.placeholderSuratIzin = pegawai.permission_letter
      this.selectedEmployee = index
      this.selectedSubmitter = submitter
      this.selectedSubmitterType = submitter.type
      this.selectedInstructor = instructor
      this.selectedInstructorType = instructor.type
      this.toggleShowModalPegawai()
    },
    confirmDelete(index) {
      this.selectedEmployee = index
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.employeeTypeIndex === 0) {
            this.pegawais.splice(this.selectedEmployee, 1)
            this.pegawaiSubmitters.splice(this.selectedEmployee, 1)
            this.pegawaiInstructors.splice(this.selectedEmployee, 1)
            this.$refs.tablePegawai.refresh()
          } else {
            this.nonPegawais.splice(this.selectedEmployee, 1)
            this.nonPegawaiSubmitters.splice(this.selectedEmployee, 1)
            this.nonPegawaiInstructors.splice(this.selectedEmployee, 1)
            this.$refs.tableNonPegawai.refresh()
          }
        }
        this.selectedEmployee = null
      })
    },
    customLabelPegawai({ nippos, nama, descjabatan }) {
      return `${nippos} / ${nama} / ${descjabatan}`
    },
    customLabelNonPegawai({ nik, name, type_non_employee }) {
      return `${nik} / ${name} / ${type_non_employee?.name}`
    },
    checkIsExists() {
      const isPegawai = this.employeeTypeIndex === 0
      const data = isPegawai ? this.pegawais : this.nonPegawais
      const key = isPegawai ? 'nippos' : 'nik'
      if (data.find(item => item[key] === this.pegawai[key])) {
        this.existsMessage = 'Gagal, pegawai sudah tersedia.'
      } else {
        this.existsMessage = ''
        this.toggleShowModalPegawai()
        this.getHistoryApproval(key)
        this.getDayTripsLeft()
      }
    },
    getHistoryApproval(key) {
      axios
        .get(`${this.config.api}/history-approvals?nippos=${this.pegawai[key]}`)
        .then(res => {
          const data = res.data?.data

          if (data && data.approval_1) {
            if (data.approval_1_type === 'pegawai') {
              /* eslint-disable-next-line prefer-destructuring, eqeqeq */
              this.selectedSubmitter = this.approvalLists.filter(
                ({ nippos }) => nippos == data.approval_1,
              )[0]
            } else {
              this.selectedSubmitterType = 'non-pegawai'
              /* eslint-disable-next-line prefer-destructuring */
              this.selectedSubmitter = this.nonPegawaiLists.filter(
                ({ nik }) => nik == data.approval_1,
              )[0]
            }
          }

          if (data && data.approval_2) {
            if (data.approval_2_type === 'pegawai') {
              /* eslint-disable-next-line prefer-destructuring, eqeqeq */
              this.selectedInstructor = this.approvalLists.filter(
                ({ nippos }) => nippos == data.approval_2,
              )[0]
            } else {
              /* eslint-disable-next-line prefer-destructuring */
              this.selectedInstructor = this.nonPegawaiLists.filter(
                ({ nik }) => nik == data.approval_2,
              )[0]
              this.selectedInstructorType = 'non-pegawai'
            }
          }
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    getDayTripsLeft() {
      const departurePeriod = moment(this.model.departure_date).format(
        'YYYY-MM',
      )
      const returnPeriod = moment(this.model.return_date).format('YYYY-MM')
      const periodMonthDifferent = departurePeriod !== returnPeriod

      const data = {
        type: this.pegawai?.nippos ? 'pegawai' : 'non-pegawai',
        pid: this.pegawai?.nippos || this.pegawai?.nik,
        periode: periodMonthDifferent
          ? [departurePeriod, returnPeriod]
          : [departurePeriod],
      }

      axios
        .post(`${this.config.api}/check_daytrips_left`, data)
        .then(res => {
          const dayTripsLeft = res.data.data
          let needUpload = false

          if (periodMonthDifferent) {
            const totalDaysFirstMonth = moment(`${returnPeriod}-01`).diff(
              this.model.departure_date,
              'days',
            )
            const totalDaysSecondMonth =
              this.model.total_days - totalDaysFirstMonth

            if (
              dayTripsLeft[0].daytrips_left - totalDaysFirstMonth < 0 ||
              dayTripsLeft[1].daytrips_left - totalDaysSecondMonth < 0
            ) {
              needUpload = true
            }
          } else {
            /* eslint-disable no-lonely-if */
            if (dayTripsLeft[0].daytrips_left - this.model.total_days < 0) {
              needUpload = true
            }
          }

          this.pegawai = {
            ...this.pegawai,
            needUpload,
          }
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    letterNumberFormatter(value) {
      if (value.length > 255) {
        this.letterNumberErrorMessage = 'Nomor surat maksimal 255 karakter.'
        return value.substr(0, 255)
      }
      this.letterNumberErrorMessage = ''
      return value
    },
    purposeFormatter(value) {
      if (value.length > 255) {
        this.purposeErrorMessage =
          'Maksud perjalanan dinas maksimal 255 karakter.'
        return value.substr(0, 255)
      }
      this.purposeErrorMessage = ''
      return value
    },
    noteFormatter(value) {
      if (value.length > 255) {
        this.noteErrorMessage = 'Catatan lainnya maksimal 255 karakter.'
        return value.substr(0, 255)
      }
      this.noteErrorMessage = ''
      return value
    },
  },
}
</script>
<style src="vue-step-indicator/dist/vue-step-indicator.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
